import { Link } from 'react-router-dom';
import './App.css';

function Home() {
	return (
		<div className='App'>
			<div className='wrapper' />
			<header className='App-header'>
				<div className='App-panel'>
					<div className='App-header-bottom'>
						<div className='App-header-bottom-left'>
							<div className='App-header-logos' style={{ display: 'flex', alignItems: 'center' }}>
								<img width={200} src={process.env.PUBLIC_URL + '/social.png'} alt='3oh logo' />
							</div>
						</div>
					</div>
					<br />
					<p>
						<a href='mailto:hello@3oh.io' className='App-link'>
							hello@3oh.io
						</a>
					</p>
				</div>
			</header>

			<footer className='App-footer'>
				<p>
					For support contact{' '}
					<a href='mailto:support@3oh.io' className='App-link'>
						support@3oh.io
					</a>
				</p>
				<p>
					Copyright &copy; 2024 3oh Inc |{' '}
					<Link to='/terms-of-service' className='App-link'>
						Terms of Service
					</Link>{' '}
					|{' '}
					<Link to='/privacy-policy' className='App-link'>
						Privacy Policy
					</Link>
				</p>
			</footer>
		</div>
	);
}

export default Home;
