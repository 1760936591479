import React from 'react';

const PrivacyPolicy = () => {
	return (
		<div className='copy'>
			<h1>3OH, INC. PRIVACY POLICY</h1>

			<h2>I. INTRODUCTION</h2>
			<p>3oh, Inc. ("3oh," "Company," "we," "us," or "our") values your privacy. This Privacy Policy informs you of your choices and our practices regarding any Information you provide to us.</p>
			<p>
				The use of our our websites including 3oh.io, Storybank.ai and Playlayer.xyz and mobile application ("Service") involves the processing of your Information. It is important that you read and understand this Privacy Policy.
			</p>

			<h2>II. THE INFORMATION WE COLLECT</h2>
			<ul>
				<li>2.1 Personal Information: We may collect personal information that you provide to us, such as your name, email address, and phone number when you register for an account.</li>
				<li>2.2 Usage Information: We may collect information about your use of the Service, including your engagement with various features and content, the time, frequency, and duration of your activities.</li>
				<li>2.3 Device and Connection Information: We may collect information about the device you use to access the Service, including your IP address, browser type, and other software or hardware information.</li>
			</ul>

			<h2>III. HOW WE USE YOUR INFORMATION</h2>
			<p>We use the Information we collect from all our services for the following purposes:</p>
			<ul>
				<li>3.1 Provide the Service: We use your Information to provide the Service to you, to facilitate communication among users, and to respond to your requests or questions.</li>
				<li>3.2 Improve the Service: We use your Information to understand how the Service is being used and how we can improve it.</li>
				<li>3.3 Communicate with You: We use your Information to communicate with you, such as sending you notifications, responding to your inquiries, or providing information about updates or changes to the Service.</li>
			</ul>

			<h2>IV. HOW WE SHARE YOUR INFORMATION</h2>
			<ul>
				<li>4.1 With Your Consent: We will share your Information with third parties when we have your consent to do so.</li>
				<li>4.2 For Legal Reasons: We may share your Information if we believe it is required by applicable law, regulation, operating agreement, legal process or governmental request.</li>
			</ul>

			<h2>V. YOUR RIGHTS AND CHOICES</h2>
			<p>You have the right to access, correct, or delete your personal information. You also have the right to object to or restrict our processing of your information.</p>

			<h2>VI. DATA SECURITY</h2>
			<p>We use reasonable measures to protect your Information from unauthorized access, alteration, or loss. However, no online activity is ever fully secure or error-free.</p>

			<h2>VII. CHANGES TO THIS PRIVACY POLICY</h2>
			<p>We may update this Privacy Policy from time to time. If we make any changes, we will notify you by revising the "Last Updated" date at the top of this Privacy Policy.</p>

			<h2>VIII. CONTACT US</h2>
			<p>If you have any questions about this Privacy Policy, please contact us at hello@metacake.io.</p>
		</div>
	);
};

export default PrivacyPolicy;
