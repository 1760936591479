import './App.css';

// Main.js

import { Route, Routes } from 'react-router-dom';
import TermsOfService from './TermsOfService'; // import the new component
import PrivacyPolicy from './PrivacyPolicy'; // import the new component
import Home from './Home';

function Main() {
	return (
		<Routes>
			<Route path='/terms-of-service' element={<TermsOfService />} />
			<Route path='/privacy-policy' element={<PrivacyPolicy />} />
			<Route path='/' element={<Home />} />
		</Routes>
	);
}

export default Main;
