import React from 'react';

const TermsOfService = () => {
	return (
		<div className='copy'>
			<h1>3OH, INC. SERVICE TERMS OF SERVICE</h1>

			<h2>I. INTRODUCTION AND ACCEPTANCE OF TERMS</h2>
			<p>
				1.0 Acceptance of Terms: By accessing or utilizing our Service, you concur with these Terms of Service, our Privacy Policy, and any additional terms which may apply to certain programs in which you elect to participate. You
				attest that you have read, understood, and agree to be bound by these Terms.
			</p>

			<h2>II. ACCOUNT REGISTRATION AND SECURITY</h2>
			<ul>
				<li>2.1 Account Creation: To access specific features of the Service, you may be required to create an account. When creating an account, you agree to provide accurate, current, and complete information about yourself.</li>
				<li>2.2 Account Security: You are solely responsible for maintaining the confidentiality of your password and account, and for any and all activities that occur under your account.</li>
			</ul>

			<h2>III. CONTENT AND INTELLECTUAL PROPERTY</h2>
			<ul>
				<li>
					3.1 User Content: You retain all rights to any content you submit, post, or display on or through the Service ("User Content"). You are solely responsible for your User Content and the consequences of its submission,
					posting, and display.
				</li>
				<li>
					3.2 License to User Content: By submitting, posting, or displaying User Content on or through the Service, you grant Metacake a worldwide, non-exclusive, royalty-free license (with the right to sublicense) to use, copy,
					reproduce, process, adapt, modify, publish, transmit, display, and distribute such Content in any and all media or distribution methods.
				</li>
				<li>
					3.3 Intellectual Property: All right, title, and interest in and to the Service (excluding User Content provided by users) are and will remain the exclusive property of Metacake and its licensors. The Service is
					protected by copyright, trademark, and other laws of both the United States and foreign countries.
				</li>
			</ul>

			<h2>IV. USER CONDUCT AND RESTRICTIONS</h2>
			<ul>
				<li>4.1 User Conduct: You are responsible for your use of the Service and for any User Content you provide, including compliance with applicable laws, rules, and regulations.</li>
				<li>
					4.2 Prohibited Actions: You agree not to engage in any of the following prohibited activities:
					<ol>
						<li>(i) copying, distributing, or disclosing any non-public part of the Service in any medium;</li>
						<li>(ii) using any automated system to access the Service;</li>
						<li>(iii) circumventing the access controls of the Service;</li>
						<li>(iv) uploading content to the service for which you do not have legal rights or permission to do so, and;</li>
						<li>(v) uploading content or imagery that is illegal in the jurisdiction in which you are location or illegal in the United States of America.</li>
					</ol>
				</li>
			</ul>

			<h2>V. DISCLAIMERS, LIMITATIONS OF LIABILITY, AND INDEMNITY</h2>
			<ul>
				<li>5.1 Disclaimer: The Service is provided on an "as is" basis without warranties of any kind, whether express or implied.</li>
				<li>
					5.2 Limitation of Liability: Metacake shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any
					loss of data, use, goodwill, or other intangible losses.
				</li>
				<li>
					5.3 Indemnity: You agree to indemnify, defend, and hold harmless Metacake, its officers, directors, employees, and agents, from and against any claims, liabilities, and losses whatsoever (including damages to property
					and injuries to or death of persons, court costs, and reasonable attorneys&rsquo; fees) occurring or resulting from any and all persons, firms or corporations furnishing or supplying work, services, materials, or
					supplies in connection with your use of the Service, and from any and all claims, liabilities, and losses occurring or resulting to any person, firm, or corporation for damage, injury, or death arising out of or
					connected with your use of the Service, unless such claims, liabilities, or losses arise out of the sole negligence or willful misconduct of Metacake.
				</li>
			</ul>

			<h2>VI. TERMINATION</h2>
			<ul>
				<li>
					6.1 Termination: Metacake may terminate or suspend your access to or ability to use any and all Services immediately, without prior notice or liability, for any reason or no reason, including but not limited to if you
					breach any of the terms or conditions of this Agreement.
				</li>
				<li>6.2 Effect of Termination: Upon termination of your access to or ability to use a Service, your right to use or access that Service and any Content will immediately cease.</li>
			</ul>

			<h2>VII. GOVERNING LAW AND JURISDICTION</h2>
			<ul>
				<li>7.1 Governing Law: These Terms of Service and any action related thereto will be governed by the laws of the State of California without regard to its conflict of laws provisions.</li>
				<li>
					7.2 Jurisdiction: The exclusive jurisdiction and venue of any action with respect to the subject matter of these Terms of Service will be the state and federal courts located in San Francisco, California, and each of the
					parties hereto waives any objection to jurisdiction and venue in such courts.
				</li>
			</ul>

			<h2>VIII. GENERAL TERMS</h2>
			<ul>
				<li>8.1 Entire Agreement: These Terms of Service constitute the entire agreement between you and Metacake and govern your use of the Service, superseding any prior agreements between you and Metacake.</li>
				<li>
					8.2 Waiver and Severability: The failure of Metacake to enforce any right or provision of these Terms will not be deemed a waiver of such right or provision. In the event that any provision of these Terms is held to be
					invalid or unenforceable, the remaining provisions of these Terms will remain in full force and effect.
				</li>
				<li>8.3 Amendments: We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect.</li>
			</ul>
		</div>
	);
};

export default TermsOfService;
